<template>
  <b-button class="basket-button" @click="openBasketModal">
    <div>
      <b-icon icon="shopping-basket" />
      <label class="ml-1 basket-label">{{
        $t("af:merchandise.basket.button.label")
      }}</label>
    </div>
  </b-button>
</template>

<script>
import eventBus, { EVENTS } from "@/utils/eventBus";

export default {
  name: "BasketButton",
  methods: {
    openBasketModal() {
      eventBus.$emit(EVENTS.OPEN_BASKET_MODAL);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

@media only screen and (max-width: 768px) {
  .basket-button {
    display: none;
  }
}

.basket-button {
  position: fixed;
  top: 21.5rem;
  right: -133px;
  background-color: #ffd43b;
  border-color: #ffd43b;
  color: $grey-dark;
  border-radius: 15px 0 0 15px;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  transition: right 0.5s;
  font-weight: bold;

  &:hover {
    right: 0;
  }
}

.basket-label {
  cursor: pointer;
}
</style>
